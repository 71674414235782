import React from "react";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import Button from "react-bootstrap/Button";

function Donate(props) {
  return (
    <div>
      <hr></hr>
      <h3>Support Us</h3>
      <hr></hr>
      <div className="description-box">
        <p>
          If you can’t get enough of our content and would like to show your
          support monetarily, you can choose to either support us monthly by
          joining our Patreon or by making a once off donation on Buy Me a
          Coffee. All your donations will be used to support our efforts in
          expanding and continuing what we do.
        </p>
      </div>
      <CardDeck className="card-deck">
        <Card>
          <Card.Body>
            <Card.Title className="card-title">Patreon</Card.Title>
            <Card.Text>
              Join our Patreon family and contribute €3 per month to our ever
              growing family and help us to continue delivering you your daily
              dose of cuteness!
            </Card.Text>
            <Button
              variant="primary"
              href="https://www.patreon.com/animaloftheday"
            >
              Patreon
            </Button>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title className="card-title">Buy us a Coffee</Card.Title>
            <Card.Text>
              Show your support with a one time payment on Buy Me a Coffee,
              where you can leave us a personalised message under your donation
              ❤ Thank you!
            </Card.Text>
            <Button
              variant="primary"
              href="https://www.buymeacoffee.com/ZBq8IarEP"
            >
              Buy us a Coffee
            </Button>
          </Card.Body>
        </Card>
      </CardDeck>
      <hr></hr>
    </div>
  );
}

export default Donate;
