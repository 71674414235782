import React from "react";

export default class Shop extends React.Component {
  componentDidMount() {
    window.location.replace("https://teespring.com/stores/animal-of-the-day");
  }
  render() {
    return window.location.replace(
      "https://teespring.com/stores/animal-of-the-day"
    );
  }
}
