import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";

import MyNav from "./Components/MyNav";
import Pig from "./Components/Pig";
import Cow from "./Components/Cow";
import Goat from "./Components/Goat";
import Rabbit from "./Components/Rabbit";
import Hamster from "./Components/Hamster";
import Frog from "./Components/Frog";
import Dog from "./Components/Dog";
import Donkey from "./Components/Donkey";

import About from "./Components/About";
import Donate from "./Components/Donate";
import Calendar from "./Components/Calendar";
import Shop from "./Components/Shop";

class App extends React.Component {
  render() {
    return (
      <Router>
        <MyNav />
        <div className="main-container">
          <Switch>
            <Route path="/cows">
              <Cow
                name="Cow"
                link="https://twitter.com/CowoftheDay1?ref_src=twsrc%5Etfw"
              />
            </Route>
            <Route path="/pigs">
              <Pig
                name="Pig"
                link="https://twitter.com/pigofthe?ref_src=twsrc%5Etfw"
              />
            </Route>
            <Route path="/rabbits">
              <Rabbit
                name="Rabbit"
                link="https://twitter.com/RabbitoftheDay?ref_src=twsrc%5Etfw"
              />
            </Route>
            <Route path="/frogs">
              <Frog
                name="Frog"
                link="https://twitter.com/frogofthe?ref_src=twsrc%5Etfw"
              />
            </Route>
            <Route path="/goats">
              <Goat
                name="Goat"
                link="https://twitter.com/goatofthe?ref_src=twsrc%5Etfw"
              />
            </Route>
            <Route path="/hamsters">
              <Hamster
                name="Hamster"
                link="https://twitter.com/hamsterofthe?ref_src=twsrc%5Etfw"
              />
            </Route>
            <Route path="/dogs">
              <Dog
                name="Dog"
                link="https://twitter.com/Dogofthe_?ref_src=twsrc%5Etfw"
              />
            </Route>
            <Route path="/donkeys">
              <Donkey
                name="Donkey"
                link="https://twitter.com/DonkeyoftheDay1?ref_src=twsrc%5Etfw"
              />
            </Route>
            <Route path="/donate">
              <Donate />
            </Route>
            <Route path="/calendar">
              <Calendar />
            </Route>
            <Route path="/shop">
              <Shop />
            </Route>
            <Route path="/">
              <About />
            </Route>
          </Switch>
        </div>
        <Navbar sticky="bottom" className="footer">
          <Container>
            <Row className="footer-content">
              <Col sm={12}>
                <a
                  href="https://www.instagram.com/animalofthedayoninsta/?hl=en"
                  className="instagram social"
                >
                  <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
              </Col>
              <Col sm={12}>
                <h6>
                  © 2022 Copyright: <b>AnimalOfTheDay.com</b>
                </h6>
              </Col>
            </Row>
          </Container>
        </Navbar>
      </Router>
    );
  }
}

export default App;
