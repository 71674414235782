import React, { Component } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Link } from "react-router-dom";

export default class MyNav extends Component {
  render() {
    return (
      <Navbar bg="light" expand="lg" sticky="top">
        <Navbar.Brand>
          <Link to="/">
            <img
              alt=""
              src="/Logo.png"
              width=""
              height="90"
              className="d-inline-block align-top"
            />{" "}
            <Link to="/" className="nav-title">
              Animal of the Day
            </Link>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <NavDropdown title="Animals" id="basic-nav-dropdown">
              <NavDropdown.Item href="/cows">Cows</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/rabbits">Rabbits</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/pigs">Pigs</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/frogs">Frogs</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/goats">Goats</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/hamsters">Hamsters</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/dogs">Dogs</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/donkeys">Donkeys</NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link href="/donate">
              <Link to="/donate">Support Us</Link>
            </Nav.Link> */}
            <Nav.Link href="/calendar">
              <Link to="/calendar">Calendar</Link>
            </Nav.Link>
            <Nav.Link href="/shop">
              <Link to="/shop">Shop</Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
