import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import Potd from "../Images/Pig-web-logo.png";

class Pig extends React.Component {
  componentDidUpdate() {
    window.twttr.widgets.load();
  }

  render() {
    window.twttr.widgets.load();
    return (
      <>
        <div className="animal-feed">
          <Container>
            <Row className="animal-main-box pig">
              <Col sm={12} lg={6} xl={4}>
                <Image src={Potd} thumbnail className="pig" />
              </Col>
              <Col sm={12} lg={6} xl={8}>
                <div className="description-box-animal pig">
                  <p>
                    We’re not telling porkies when we say that Pig of the Day is
                    the daily content you never knew you needed until you found
                    it!
                  </p>
                  <a
                    href="https://twitter.com/pigofthe?ref_src=twsrc%5Etfw"
                    className="twitter-follow-button"
                    data-size="large"
                    data-show-screen-name="false"
                    data-show-count="false"
                  >
                    Follow @pigofthe
                  </a>
                  <script
                    async
                    src="https://platform.twitter.com/widgets.js"
                    charSet="utf-8"
                  ></script>
                </div>
              </Col>
            </Row>
          </Container>

          <hr></hr>
          <div className="animal-feed-container">
            <a
              className="twitter-timeline"
              data-theme="light"
              href={this.props.link}
            >
              Loading the {this.props.name}s .....
            </a>
          </div>
        </div>
      </>
    );
  }
}

export default Pig;
