import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import TweetEmbed from "react-tweet-embed";

function About(props) {
  return (
    <div>
      <br />
      <Image className="main-image" src="/aotd.jpg" fluid />
      <div className="description-box">
        <p>
          Welcome to Animal of the Day, a haven for all you animal lovers! What
          we do is simple; we share your photos of your favourite animals daily
          for your viewing pleasure. Every day we post a new cow, frog, goat,
          hamster, pig and rabbit for you, most of which have been submitted to
          us by our followers.
        </p>
        <p>
          On our website, you can scroll through our timelines and get your fix
          of cute animals to brighten up your day!
        </p>
      </div>
      <Container>
        <Row>
          <Col xs={3} md={3} className="animal-nav-image-box">
            <a href="/cows">
              <Image
                src="Cow-web-logo.png"
                thumbnail
                className="animal-nav-image"
              />
            </a>
          </Col>
          <Col xs={3} md={3} className="animal-nav-image-box">
            <a href="/rabbits">
              <Image
                src="Rabbit-web-logo.png"
                thumbnail
                className="animal-nav-image"
              />
            </a>
          </Col>
          <Col xs={3} md={3} className="animal-nav-image-box">
            <a href="/pigs">
              <Image
                src="Pig-web-logo.png"
                thumbnail
                className="animal-nav-image"
              />
            </a>
          </Col>
          <Col xs={3} md={3} className="animal-nav-image-box">
            <a href="/frogs">
              <Image
                src="Frog-web-logo.png"
                thumbnail
                className="animal-nav-image"
              />
            </a>
          </Col>
          <Col xs={3} md={3} className="animal-nav-image-box">
            <a href="/goats">
              <Image
                src="Goat-web-logo.png"
                thumbnail
                className="animal-nav-image"
              />
            </a>
          </Col>
          <Col xs={3} md={3} className="animal-nav-image-box">
            <a href="/hamsters">
              <Image
                src="Hamster-web-logo.png"
                thumbnail
                className="animal-nav-image"
              />
            </a>
          </Col>
          <Col xs={3} md={3} className="animal-nav-image-box">
            <a href="/dogs">
              <Image
                src="Dog-web-logo.png"
                thumbnail
                className="animal-nav-image"
              />
            </a>
          </Col>
          <Col xs={3} md={3} className="animal-nav-image-box">
            <a href="/donkeys">
              <Image
                src="Donkey-web-logo.png"
                thumbnail
                className="animal-nav-image"
              />
            </a>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col xs={12} md={6}>
            <TweetEmbed id="1484449940730384387" />
            <TweetEmbed id="1484796725579501568" />
            <TweetEmbed id="1478658781206626306" />
            <TweetEmbed id="1483727964512661504" />
          </Col>
          <Col xs={12} md={6}>
            <TweetEmbed id="1480975295242899456" />
            <TweetEmbed id="1481327365221339143" />
            <TweetEmbed id="1471200134172233734" />
            <TweetEmbed id="1484235528413327363" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default About;
