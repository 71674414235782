import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import COTDcalendar from "../Images/COTDcalendar.png";
// import ROTDcalendar from "../Images/ROTDcalendar.png";
// import POTDcalendar from "../Images/POTDcalendar.png";
// import FOTDcalendar from "../Images/FOTDcalendar.png";
// import GOTDcalendar from "../Images/GOTDcalendar.png";
// import HOTDcalendar from "../Images/HOTDcalendar.png";
// import DOTDcalendar from "../Images/DOTDcalendar.png";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Calendar(props) {
  return (
    <div>
      <br />
      <hr></hr>
      <h3 className="calendar-title">2022 Calendars</h3>
      <hr></hr>
      <div className="description-box calendar-description">
        <p>
          Get your very own Animal of the Day calendar through the links below.
          We want to thank you all for the love you have shown us over the last
          two years. We really appreciate all your support. Most importantly, we
          hope you love the new calendars!
        </p>
      </div>
      <Container>
        <Row>
          <Col sm={12} md={12} lg={6} className="animal-nav-image-box">
            <Card>
              <Card.Img variant="top" src={COTDcalendar} />
              <Card.Body>
                <Card.Title>Cow of the Day 2022</Card.Title>
                <Button
                  variant="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href =
                      "https://www.lulu.com/en/gb/shop/cow-of-the-day-/cow-of-the-day-2022/paperback/product-4ey8nn.html?fbclid=IwAR3ZwVVfzPdRyy0WhK0HMjRV_ugUzvsUFEFX_6sj2k5RoSlF2tuYer6mozg&page=1&pageSize=4";
                  }}
                >
                  Check it out
                </Button>
              </Card.Body>
            </Card>
          </Col>
          {/* <Col xs={6} md={4} className="animal-nav-image-box">
            <Card>
              <Card.Img variant="top" src={ROTDcalendar} />
              <Card.Body>
                <Card.Title>Rabbit of the Day 2021</Card.Title>
                <Button
                  variant="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href =
                      "https://www.lulu.com/en/gb/shop/animal-of-the-day-/rabbit-of-the-day-2021-calendar/paperback/product-wqm6kg.html";
                  }}
                >
                  Check it out
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={6} md={4} className="animal-nav-image-box">
            <Card>
              <Card.Img variant="top" src={POTDcalendar} />
              <Card.Body>
                <Card.Title>Pig of the Day 2021</Card.Title>
                <Button
                  variant="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href =
                      "https://www.lulu.com/en/gb/shop/animal-of-the-day-/pig-of-the-day-2021-calendar/paperback/product-njpwqd.html";
                  }}
                >
                  Check it out
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={6} md={4} className="animal-nav-image-box">
            <Card>
              <Card.Img variant="top" src={FOTDcalendar} />
              <Card.Body>
                <Card.Title>Frog of the Day 2021</Card.Title>
                <Button
                  variant="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href =
                      "https://www.lulu.com/en/gb/shop/animal-of-the-day-/frog-of-the-day-2021-calendar/paperback/product-7j7g4m.html";
                  }}
                >
                  Check it out
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={6} md={4} className="animal-nav-image-box">
            <Card>
              <Card.Img variant="top" src={GOTDcalendar} />
              <Card.Body>
                <Card.Title>Goat of the Day 2021</Card.Title>
                <Button
                  variant="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href =
                      "https://www.lulu.com/en/gb/shop/animal-of-the-day-/goat-of-the-day-2021-calendar/paperback/product-m58j82.html";
                  }}
                >
                  Check it out
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={6} md={4} className="animal-nav-image-box">
            <Card>
              <Card.Img variant="top" src={HOTDcalendar} />
              <Card.Body>
                <Card.Title>Hamster of the Day 2021</Card.Title>
                <Button
                  variant="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href =
                      "https://www.lulu.com/en/gb/shop/animal-of-the-day-/hamster-of-the-day-2021-calendar/paperback/product-y926nr.html";
                  }}
                >
                  Check it out
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={6} md={4} className="animal-nav-image-box">
            <Card>
              <Card.Img variant="top" src={DOTDcalendar} />
              <Card.Body>
                <Card.Title>Dog of the Day 2021</Card.Title>
                <Button
                  variant="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href =
                      "https://www.lulu.com/en/gb/shop/animal-of-the-day-/dog-of-the-day-2021-calendar/paperback/product-4jkm48.html";
                  }}
                >
                  Check it out
                </Button>
              </Card.Body>
            </Card>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
}

export default Calendar;
