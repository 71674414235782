import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import Rotd from "../Images/Rabbit-web-logo.png";

class Rabbit extends React.Component {
  componentDidUpdate() {
    window.twttr.widgets.load();
  }

  render() {
    window.twttr.widgets.load();
    return (
      <>
        <div className="animal-feed">
          <Container>
            <Row className="animal-main-box rabbit">
              <Col sm={12} lg={6} xl={4}>
                <Image src={Rotd} thumbnail className="rabbit" />
              </Col>
              <Col sm={12} lg={6} xl={8}>
                <div className="description-box-animal rabbit">
                  <p>
                    Rabbit of the Day is where it all started, you can expect
                    daily fluffy buns on your newsfeed but also a community full
                    of support for your rabbits.
                  </p>
                  <a
                    href="https://twitter.com/RabbitoftheDay?ref_src=twsrc%5Etfw"
                    className="twitter-follow-button"
                    data-size="large"
                    data-show-screen-name="false"
                    data-show-count="false"
                  >
                    Follow @pigofthe
                  </a>
                  <script
                    async
                    src="https://platform.twitter.com/widgets.js"
                    charSet="utf-8"
                  ></script>
                </div>
              </Col>
            </Row>
          </Container>

          <hr></hr>
          <div className="animal-feed-container">
            <a
              className="twitter-timeline"
              data-theme="light"
              href={this.props.link}
            >
              Loading the {this.props.name}s .....
            </a>
          </div>
        </div>
      </>
    );
  }
}

export default Rabbit;
